import { isEmpty, isNil, trim } from 'lodash';

export const removeString = (originalString, stringToRemove) => {
  let regex = new RegExp(stringToRemove, 'g');
  let newString = originalString.replace(regex, '');
  return newString;
};

export const getDisplayControlName = (item) => {
  let finalControlName = '';
  const controlName = trim(item?.ControlName);
  const controlNumber = trim(item?.ControlNumber);
  if (!isEmpty(controlName) && !isNil(controlName) && !isEmpty(controlNumber) && !isNil(controlNumber)) {
    const isControlNumberInsideName = controlName?.includes(controlNumber);
    const isControlNameInsideNumber = controlNumber?.includes(controlName);
    if (isControlNumberInsideName && !isControlNameInsideNumber) {
      let deduplicatedString = removeString(controlName, controlNumber);
      finalControlName = controlNumber.concat(' ', deduplicatedString);
    } else if (!isControlNumberInsideName && isControlNameInsideNumber) {
      let deduplicatedString = removeString(controlNumber, controlName);
      finalControlName = deduplicatedString.concat(' ', controlName);
    } else if (isControlNumberInsideName && isControlNameInsideNumber) {
      finalControlName = controlName;
    } else {
      finalControlName = controlNumber.concat(' ', controlName);
    }
  } else if (isEmpty(controlNumber) || isNil(controlNumber)) {
    finalControlName = controlName;
  } else {
    finalControlName = controlNumber.concat(' ', controlName);
  }

  return finalControlName;
};

export const standardizeHyperlink = (url) => {
  if (url && !url.startsWith('//')) {
    return `//${url}`;
  }
  return url;
};

export const htmlStringToText = (htmlString) => {
  let temp = document.createElement('div');
  temp.innerHTML = htmlString;
  return temp.textContent || temp.innerText || '';
};
